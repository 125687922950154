import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Categories = ({uri, subsection, categories, location}) => {
    let component = [];
    const allowed = {
        'videos': true, 
        'memes': true, 
        'gifs': true, 
        'monologos': true
    };

    for (let slug in categories)
        if (allowed[slug])
            component.push(<li key={`header-${slug}`} className={location && slug===subsection && location.pathname.indexOf('categoria') !== -1 ? 'current' : ''} >
                <Link to={`${uri}/${slug}/`}>
                    {categories[slug]}
                </Link>
            </li>);

    return component;
}

Categories.propTypes = {
    uri: PropTypes.string,
    subsection: PropTypes.string,
    categories: PropTypes.object,
}
  
Categories.defaultProps = {
    uri: ``,
    subsection: ``,
    categories: {}
}

export default Categories