import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import {getShareImage} from '../helpers/react/utils';
import {
    FacebookShareButton, FacebookIcon, 
    TwitterShareButton, TwitterIcon, 
    TelegramShareButton, TelegramIcon, 
    WhatsappShareButton, WhatsappIcon, 
    LineShareButton, LineIcon,
    EmailShareButton, EmailIcon

} from "react-share";

const Share = ( {node, indexed}) => {
    const {site} = useStaticQuery(graphql`
        query SiteUrlQuery {
            site {
                siteMetadata {
                    url
                }
            }
        }
    `);
    const media = getShareImage(node, indexed, site.siteMetadata.url);
    let tags;

    if (node.tags) 
        tags = node.tags.value.map(tag => tag);

    /*<div 
        className="addthis_sharing_toolbox" 
        data-url={`${site.siteMetadata.url}/${node.title.slug}`} 
        data-title={node.title.value}
        data-description={node.description ? node.description.value : ''}
        data-media={media}>
    </div>;*/

    return <div className="sns">
        <FacebookShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} quote={node.title.value} hashtags={tags}>
            <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} title={node.title.value} quote={node.title.value} hashtags={tags}>
            <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} title={node.title.value}>
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TelegramShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} title={node.title.value}>
            <TelegramIcon size={32} round />
        </TelegramShareButton>
        <LineShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} title={node.title.value}>
            <LineIcon size={32} round />
        </LineShareButton>
        <EmailShareButton url={`${site.siteMetadata.url}/${node.title.slug}`} subject={node.title.value} body={node.description ? `${node.description.value} ${media}` : media}>
            <EmailIcon size={32} round />
        </EmailShareButton>
    </div>;
}

Share.propTypes = {
    node: PropTypes.object,
    indexed: PropTypes.object
}

export default Share;