import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Helmet from "react-helmet";
import Categories from './categories';
import Featured from './featured';

class Header extends Component {
    state = {
        showMobileMenu: false
    }

    toggleMenu = (e) => {
        e.preventDefault();

        this.setState({
            showMobileMenu: !this.state.showMobileMenu
        });
    }

    render() {
        const { siteTitle, uris, page, section, subsection, categories, location, title, description, shareImage } = this.props;
        const sectionClassName = (page === 1 && section === 'latest' ? 's-pageheader s-pageheader--home' : 's-pageheader');

        return <section className={sectionClassName}>
            <Helmet>
                <script>
                    {`var infolinks_pid = 3257254; var infolinks_wsid = 0;`}
                </script>
            </Helmet>
            <header className="header">
                <div className="header__content row">
                    <div className="header__logo">
                        <Link to="/" className="logo">
                            {siteTitle}
                        </Link>
                    </div>
                    <a onClick={this.toggleMenu} className="header__toggle-menu" href="#0" title="Menu"><span>Menu</span></a>
                    <nav className={ this.state.showMobileMenu ? "header__nav-wrap header__nav-wrap-display" : "header__nav-wrap"}>
                        <ul className="header__nav">
                            <li className={section==='latest' ? 'current' : ''}>
                                <Link to={uris.latest==='' ? '/' : `${uris.latest}/`}>
                                    Más recientes
                                </Link>
                            </li>
                            <li className={section==='featured' ? 'current' : ''}>
                                <Link to={`${uris.featured}/`}>
                                    Destacados
                                </Link>
                            </li>
                            <Categories 
                                uri={uris.listingByCategory} 
                                subsection={subsection} 
                                categories={categories}
                                location={location}
                            />
                            <li className="short">
                                <a aria-label="facebook" href="https://www.facebook.com/Mepartoclub-113313510360574" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li className="short">
                                <a aria-label="twitter" href="https://twitter.com/MepartoC" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            {/*<li className="short">
                                <a aria-label="instagram" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li className="short">
                                <a aria-label="pinterest" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                                </a>
                            </li>*/}
                        </ul>
                        <a onClick={this.toggleMenu} href="#0" title="Cerrar menú" className="header__overlay-close close-mobile-menu">Cerrar</a>
                    </nav>
                </div>
            </header>
            <Featured
                page={page} 
                section={section}
                subsection={subsection}
                categories={categories}
                location={location}
                title={title}
                description={description}
                shareImage={shareImage}
            />
        </section>
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  section: PropTypes.string,
  subsection: PropTypes.string,
  page: PropTypes.number,
  uris: PropTypes.object,
  indexedImages: PropTypes.object,
  homeFeaturedPosts: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  section: '',
  subsection: ''
}

export default Header
