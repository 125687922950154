module.exports = class Media {
    getMedia({ images }, media, all) {
        let tmp = {
            'images': [],
            'gifs': []
        };

        if (media && Object.keys(media).length === 0)
            media = {
                'images': [],
                'gifs': []
            };

        if (all && Object.keys(all).length === 0)
            all = {
                'images': [],
                'gifs': []
            };

        if (images)
            images.value.forEach(image => {
                image = image.value.data.value;
                if (image.indexOf('storage') !== -1) {
                    let img = image.split('\\').pop().split('/').pop();
                    let [name, extension] = img.split('.');

                    if (extension.toLowerCase() === 'gif') {
                        tmp.gifs.push(name);
                        if (media)
                            media.gifs.push(name);
                        if (all)
                            all.gifs.push(name);
                    } else {
                        tmp.images.push(name);

                        /*if (media && !media.images)
                            console.log(media.images, name)*/

                        if (media)
                            media.images.push(name);
                        if (all)
                            all.images.push(name);
                        //all.images.push(`${process.env.PROJECT_PATH}${image}`);
                    }
                }
            });

        return {
            tmp,
            media: media || null,
            all: all || null
        };
    }

    getVideoEmbeds({ videos }) {
        if (!videos)
            return [];

        return videos.value.reduce((result, video) => {
            video = video.value.data.value;
            if (video.indexOf('iframe') !== -1)
                result.push(this.getVideoLink(video));

            else if (video.indexOf('http') !== -1)
                result.push(video);

            return result;
        }, []);
    }

    getVideoLink(str) {
        const regex = /\bhttps?:\/\/\S+/gi;
        return regex.exec(str)[0].replace('"', '');
    }

    indexImagesByFilename(images) {
        let indexedImages = {};

        if (images)
            images.nodes.forEach(image => {
                let key = image.childImageSharp.fluid.originalName.split('.')[0];
                indexedImages[key] = image.childImageSharp.fluid;
            });

        return indexedImages;
    }

    indexGifsByFilename(gifs) {
        let indexedGifs = {};

        if (gifs)
            gifs.nodes.forEach(gif => {
                let key = gif.name;
                indexedGifs[key] = gif.publicURL;
            });

        return indexedGifs;
    }

    getNeededImages(indexed, tmpImages, featuredImages) {
        let indexedImages = {
            images: {},
            gifs: {}
        };

        for( let key in indexed.images)
            if (tmpImages.images.includes(key) || featuredImages.images.includes(key))
                indexedImages.images[key] = indexed.images[key];
        
        for( let key in indexed.gifs)
            if (tmpImages.gifs.includes(key) || featuredImages.gifs.includes(key))
                indexedImages.gifs[key] = indexed.gifs[key];

        return indexedImages;
    }
}