/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AddThisOptions from '../../plugins/gatsby-theme-addthis/components/AddThisOptions';
import Header from './header';
import Footer from './footer';
import '../css/base.css';
import '../css/main.css';

const Layout = ({ children, homeFeaturedPosts, uris, indexedImages, page, section, subsection, categories, location, title, description, shareImage }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const addThisOptions = {
        ui_language: 'es',
        data_track_addressbar: true,
        data_ga_property: null, // TODO
        data_ga_social : true
    };

    return (
        <>
            <Header 
                siteTitle={data.site.siteMetadata.title}  
                uris={uris} 
                page={page}
                section={section}
                subsection={subsection}
                categories={categories}
                location={location}
                title={title}
                description={description}
                shareImage={shareImage}
            />
            <main className={`s-content ${section==='latest' && page===1 ? 'home' : ''}`}>
                {children}
            </main>
            <Footer
                siteTitle={data.site.siteMetadata.title}
                uris={uris}
                indexedImages={indexedImages}
                categories={categories}
                subsection={subsection}
                homeFeaturedPosts={homeFeaturedPosts}
            />
            <AddThisOptions options={addThisOptions} />
        </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.string,
  subsection: PropTypes.string,
  page: PropTypes.number,
  uris: PropTypes.object,
  indexedImages: PropTypes.object,
  homeFeaturedPosts: PropTypes.array
}

export default Layout
