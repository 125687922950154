import React, {Component} from 'react';
import { Link } from 'gatsby';
import Loadable from 'react-loadable';
import Img from 'gatsby-image/withIEPolyfill';
import Iframe from 'react-iframe';
import ModalVideo from 'react-modal-video';

import '../css/gifs.css';
import '../css/video.css';

import Media from '../helpers/Media';
const media = new Media();

const GifPlayer = Loadable({
    loader: () => import('react-gif-player'),
    loading() {
        return <div>Cargando...</div>;
    },
    delay: 300
});

class Embed extends Component {
    state = {
        isOpen: false
    }
    
    openOpenModal = () => {
        this.setState({isOpen: true});
    }

    onCloseModal = () => {
        this.setState({isOpen: false});
    }

    getStillGifPath(path) {
        const parts = path.split('/');
        const fixed = parts.reduce( (accumulator, part, index) => {
            if (parts.length-2 === index)
                return accumulator.concat('gifs');

            return accumulator.concat(part);
        }, []).join('/');

        return fixed.replace('.gif', '.jpg');
    }

    getVideoCover(code) {
        return `/static/videos/${code}.jpg`;
    }

    render() {
        const {indexed, node, alt, imageOnly, videoIframe} = this.props;
        let images = [];

        if (node.images)
            images = node.images.value.reduce( (result, image, index) => {
                image = image.value.data.value;
                if (image.indexOf('storage') !== -1) {
                    
                    let filename = image.split('\\').pop().split('/').pop();
                    let [key, extension] = filename.split('.');
                    //console.log(indexed.images[key])

                    if (extension.toLowerCase() === 'gif' && indexed.gifs[key])
                        result.push(
                            imageOnly ?
                                <img
                                    key={`img-${key}`}
                                    alt={alt}
                                    src={this.getStillGifPath(indexed.gifs[key])}
                                />
                            :
                                <GifPlayer 
                                    key={`img-${index}`} 
                                    gif={indexed.gifs[key]} 
                                    still={this.getStillGifPath(indexed.gifs[key])}
                                    autoplay={false} 
                                    alt={alt} 
                                />
                        );

                    else if (indexed.images[key])
                        result.push(
                            imageOnly ?
                                <img
                                    key={`img-${key}`}
                                    alt={alt}
                                    src={indexed.images[key].src}
                                />
                            :
                                <Link key={`Img-${index}`} to={`/${node.title.slug}/`} className="entry__thumb-link">
                                    <Img 
                                        fluid={indexed.images[key]}
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                        alt={alt} 
                                    />
                                </Link>
                        );
                }
                return result;
            }, []);

        let videos = media.getVideoEmbeds(node).map( (video, index) => {
            if (!videoIframe && video.indexOf('youtube.com')!==-1) {
                const code = video.split('/').pop();
                

                if (imageOnly)
                    //return <div style={{backgroundImage: `url(https://img.youtube.com/vi/${code}/hqdefault.jpg)`}}></div>
                    //return <img key={`video-still-${code}`} src={`https://img.youtube.com/vi/${code}/hqdefault.jpg`} alt={node.title.value} />;
                    return <img
                        key={`video-still-${code}`}
                        alt={node.title.value}
                        src={this.getVideoCover(code)}
                    />;

                return <div key={`video-modal-${code}`}>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={code} onClose={this.onCloseModal} />
                    <div role="button" tabIndex="0" className="video-thumbnail" onClick={this.openOpenModal} onKeyDown={this.openOpenModal}>
                        <img src={this.getVideoCover(code)} alt={node.title.value} />
                    </div>
                </div>;
            }

            return (
                <div key={`iframe-${index}`} className="video-responsive">
                    <Iframe url={video} />
                </div>
            );
        });

        if (images && videos)
            return [...videos, ...images];

        if (images)
            return images;
        
        if (videos)
            return videos;

        return <></>;
    }
}

export default Embed
