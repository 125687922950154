import { Link } from 'gatsby';
import slugify from 'slugify';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import Embed from './embed';
import Share from './share';

const Article = ({node, indexed, uris, type, related}) => {

    if (type==='small')
        return <>
            <LazyLoad offset={100}>
                <article className="col-block popular__post">
                    <Link to={`/${node.title.slug}/`} className="thumb">
                        <Embed indexed={indexed} node={node} alt={node.title.value} imageOnly />
                    </Link>
                    <h5>
                        <Link to={`/${node.title.slug}/`}>
                            {node.title.value}
                        </Link>
                    </h5>
                    <section className="popular__meta">
                        <div className="entry__header">
                            {node.category && node.category.value.map( category => 
                                <span key={`footer-${node.id}-${category.name_slug}`} className="entry__category">
                                    <Link to={`${uris.listingByCategory}/${category.name_slug}/`}>
                                        {category.name}
                                    </Link>
                                </span>
                            )}
                        </div>
                        <div className="entry__meta">
                            <span className="entry__meta-links">
                                {node.tags && node.tags.value.map( tag => {
                                    const slug = slugify(tag);
                                    return <Link key={`${node.id}-${slug}`} to={`${uris.listingByTag}/${slug}/`}>
                                        {tag}
                                    </Link>
                                })}
                            </span>
                        </div>
                    </section>
                </article>
            </LazyLoad>
        </>

    else if (type==='next')
        return <LazyLoad offset={100}>
            <article className="col-block popular__post next">
                <Link to={`${uris.featured}/`} className="thumb">
                    <i className="fa fa-arrow-circle-right"></i>
                </Link>
                <h5>
                    <Link to={`${uris.featured}/`}>
                        Ver más destacados
                    </Link>
                </h5>
            </article>
        </LazyLoad>

    else if (type==='page')
        return <>
            <LazyLoad offset={100}>
                <article>
                    <div className="entry__thumb">
                        <Embed indexed={indexed} node={node} alt={node.title.value} videoIframe />
                    </div>
                    <div className="entry__text">
                        <div className="entry__date">
                            {node.cockpitModified}
                        </div>
                        <div className="description">
                            {node.description.value}
                        </div>
                        <div className="entry__header">
                            {node.category && node.category.value.map( category => 
                                <span key={`${node.id}-${category.name_slug}`} className="entry__category">
                                    <Link to={`${uris.listingByCategory}/${category.name_slug}/`}>
                                        {category.name}
                                    </Link>
                                </span>
                            )}
                        </div>
                        <div className="entry__meta">
                            <span className="entry__meta-links">
                                {node.tags && node.tags.value.map( tag => {
                                    const slug = slugify(tag);
                                    return <Link key={`${node.id}-${slug}`} to={`${uris.listingByTag}/${slug}/`}>
                                        {tag}
                                    </Link>
                                })}
                            </span>
                        </div>
                        <Share key={`share-${node.id}`} indexed={indexed} node={node} />
                    </div>
                </article>
            </LazyLoad>
        </>

    else if (type==='ad')
        return <LazyLoad offset={100}>
            <article className="ad">
                <img src="https://upload.wikimedia.org/wikipedia/commons/2/24/Ad-MediumRectangle-300x250.jpg" alt="temp ad" />
            </article>
        </LazyLoad>

    return <>
        {/*<LazyLoad offset={100}>*/}
            <article>
                <div className="entry__text">
                    <div className="entry__header">
                        {related && <h3 className="entry__title">
                            <Link to={`/${node.title.slug}/`}>
                                {node.title.value}
                            </Link>
                        </h3>}
                        {!related && <h2 className="entry__title">
                            <Link to={`/${node.title.slug}/`}>
                                {node.title.value}
                            </Link>
                        </h2>}
                    </div>
                    <div className="entry__date">
                        {node.cockpitModified}
                    </div>
                </div>
                <div className="entry__thumb">
                    <Embed indexed={indexed} node={node} alt={node.title.value} />
                </div>
                <div className="entry__text">
                    <div className="entry__header">
                        {node.category && node.category.value.map( category => 
                            <span key={`${node.id}-${category.name_slug}`} className="entry__category">
                                <Link to={`${uris.listingByCategory}/${category.name_slug}/`}>
                                    {category.name}
                                </Link>
                            </span>
                        )}
                    </div>
                    <div className="entry__meta">
                        <span className="entry__meta-links">
                            {node.tags && node.tags.value.map( tag => {
                                const slug = slugify(tag);
                                return <Link key={`${node.id}-${slug}`} to={`${uris.listingByTag}/${slug}/`}>
                                    {tag}
                                </Link>
                            })}
                        </span>
                    </div>
                    <Share key={`share-${node.id}`} indexed={indexed} node={node} />
                </div>
            </article>
        {/*</LazyLoad>*/}
    </>
}

Article.propTypes = {
    node: PropTypes.object,
    uris: PropTypes.object,
    indexed: PropTypes.object,
    type: PropTypes.string
}
  
Article.defaultProps = {
    node: {},
    uris: {},
    indexed: {},
    type: 'normal'
}

export default Article