import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Categories from './categories';
import Article from './article';

class Footer extends Component { 
    _isMounted = false;

    state = {
        isTop: true
    }

    componentDidMount() {
        this._isMounted = true;

        if (window.addthis && window.addthis.layers && window.addthis.layers.hasOwnProperty('refresh'))
            window.addthis.layers.refresh();

        document.addEventListener('scroll', () => {
            if (!this._isMounted)
                return;

            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { siteTitle, uris, categories, subsection, homeFeaturedPosts, indexedImages } = this.props;

        return <footer className="s-footer">
            <div className="s-footer__main">
                <div className="row top">
                    <div className="col-full popular">
                        <h4>Destacados</h4>

                        <div className="block-1-3 block-m-full popular__posts">
                            {homeFeaturedPosts.map( ({ node }, index) =>
                                <Article key={`featured-${node.id}`} node={node} indexed={indexedImages} uris={uris} type="small" />
                            )}
                            <Article key={`featured-next`} indexed={indexedImages} uris={uris} type="next" />
                        </div>
                    </div>
                </div>
                <div className="row bottom tags-wrap">
                    <div className="col-full tags">
                        <h4>Etiquetas destacadas</h4>
                        <div className="tagcloud">
                            <StaticQuery
                                query={graphql`
                                    query {
                                        tags: allCockpitTags(limit:23, sort: {fields: count___value, order: DESC}) {
                                            edges {
                                                node {
                                                    name {
                                                        slug
                                                        value
                                                    }
                                                }
                                            }
                                        }
                                    }
                                `}
                                render={ ({tags}) => (
                                    tags.edges.map( ({node}) =>
                                        <Link key={`footer-tags-${node.name.slug}`} to={`${uris.listingByTag}/${node.name.slug}/`}>
                                            {node.name.value}
                                        </Link>
                                    )
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-two md-four mob-1-2 s-footer__sitelinks">
                        <h4>Secciones</h4>
                        <ul className="s-footer__linklist">
                            <li>
                                <Link to={uris.latest==='' ? '/' : `${uris.latest}/`}>
                                    Más recientes
                                </Link>
                            </li>
                            <li>
                                <Link to={`${uris.featured}/`}>
                                    Destacados
                                </Link>
                            </li>
                            <li>
                                <Link to={`${uris.contact}/`}>
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-two md-four mob-1-2 s-footer__archives">
                        <h4>Categorías</h4>
                        <ul className="s-footer__linklist">
                            <Categories 
                                uri={uris.listingByCategory} 
                                subsection={subsection} 
                                categories={categories}
                            />
                        </ul>
                    </div>
                    <div className="col-two md-four mob-1-2 s-footer__social">
                        <h4>Redes sociales</h4>
                        <ul className="s-footer__linklist">
                            <li>
                                <a aria-label="facebook" href="https://www.facebook.com/Mepartoclub-113313510360574" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-facebook" aria-hidden="true"></i> Facebook
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter" href="https://twitter.com/MepartoC" target="_blank" rel="noopener noreferrer nofollow">
                                    <i className="fa fa-twitter" aria-hidden="true"></i> Twitter
                                </a>
                            </li>
                            {/*<li><a href="#0">Instagram</a></li>
                            <li><a href="#0">Pinterest</a></li>*/}
                        </ul>
                    </div>
                    <div className="col-five md-full end s-footer__subscribe">
                        <h4>Sobre nosotros</h4>
                        <p><font className="footer-title">{siteTitle}</font> es un sitio web donde recogemos contenidos destacados y variados de humor en diversos formatos (imágenes estáticas, gifs animados, vídeos, etc.) provenientes de varias fuentes tales como: páginas webs, Whatsapp, Telegram, redes sociales, etc.</p>
                        <p>Aunque normalmente desconocemos la fuente original, siempre y cuando sepamos sobre el autor de cada contenido pondremos una referencia al mismo. De modo que si alguien identifca algún contenido propio (y lo pueda demostrar) que se ponga en contacto con nostros.</p>
                    </div>
                </div>
            </div>
            <div className="s-footer__bottom">
                <div className="row">
                    <div className="col-full">
                        <div className="s-footer__copyright">
                            <span>© {new Date().getFullYear()} <font className="footer-title">{siteTitle}</font></span> 
                        </div>
                        <div className={this.state.isTop ? 'go-top' : 'go-top link-is-visible'}>
                            <a className="smoothscroll" title="Subir hacia arriba" href="#top"></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    }
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  uris: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired
}

export default Footer
