import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from "./seo";
//import '../css/featured.css';

const Featured = ({page, section, subsection, categories, location, title, description, shareImage}) => {
    let pretitle = '';
    let subtitle = '';
    let subtitleHome = '';
    let seo = {
        title: '',
        description: '',
        //url: (location && location.href ? location.href.replace(/\/?$/, '/') : ''), // append trailing slash
        pathname: (location.pathname ? location.pathname.replace(/\/?$/, '/') : ''),
        image: shareImage || ''
    };
    
    const {tags} = useStaticQuery(graphql`
        query {
            tags: allCockpitTags {
                edges {
                    node {
                        name {
                            slug
                            value
                        }
                    }
                }
            }
        }
    `);

    if (title) {
        seo.title = title;
        seo.description = description;
        pretitle = <div className="main-title">
            <div className="row">
                <h1>{seo.title}</h1>
            </div>
        </div>;

    } else if (page === 1 && section === 'latest') {
        seo.title = 'Página web de humor, chistes, memes, GIFs animados y vídeos.';
        pretitle = <div className="main-title">
            <div className="row">
                <h1>{seo.title}</h1>
            </div>
        </div>;

    } else {
        switch (section) {
            case 'listingByCategory':
                seo.title = `Humor y chistes en la categoría ${categories[subsection]}`;
                subtitle = <>
                    Humor y chistes en la categoría
                    <em>{categories[subsection]}</em>
                </>;
                break;
            
            case 'listingByTag':                
                let tagDic = {};
                tags.edges.forEach( ({node}) => {
                    tagDic[node.name.slug] = node.name.value;
                });

                seo.title = `Humor y chistes con la etiqueta ${tagDic[subsection]}`;
                subtitle = <>
                    Humor y chistes con la etiqueta
                    <em>{tagDic[subsection]}</em>
                </>;
                break;

            case 'featured':
                seo.title = subtitle = 'Lo más destacado en humor, chistes, memes, GIFs animados, monólogos y vídeos.';
                break;
                
            case 'contact':
                seo.title = subtitle = 'Contacto';
                break;

            default:
                seo.title = subtitle = 'Lo más reciente en humor, chistes, memes, GIFs animados, monólogos y vídeos.';
        }

        let pageNumber = '';
        if (page > 1) {
            pageNumber = <span>(Página {page})</span>;
            seo.title += ` - Página ${page}`; 
        }

        subtitle = <div className="title-wrapper">
            <h1 className="title-white">
                {subtitle}
                {pageNumber}
            </h1>
        </div>;
    }

    return  <>
        <SEO 
            description={seo.description} 
            title={seo.title}
            pathname={seo.pathname}
            image={seo.image}
        />
        {pretitle}
        <div className="pageheader-content row">
            <div className="col-full">
                {subtitle}
            </div>
        </div>
        {subtitleHome}
    </>;
}

Featured.propTypes = {
    uri: PropTypes.object,
    indexedImages: PropTypes.object,
    page: PropTypes.number,
    section: PropTypes.string,
    subsection: PropTypes.string,
    categories: PropTypes.object,
}
  
Featured.defaultProps = {
    uris: {},
    indexedImages: {},
    page: 1,
    section: '',
    subsection: '',
    categories: {}
}

export default Featured